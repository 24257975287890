import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
import Client from './Pages/client';
import Portfolio from './Pages/Portfolio';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Categories from './Pages/Categories';
// import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import VillaMaintenanceContractDubaiVillaAMCDubai from './Pages/Villa Maintenance Contract Dubai Villa AMC Dubai';
import BestAnnualMaintenanceContractPackages from './Pages/Best Annual Maintenance Contract Packages';
import AnnualMaintenanceContractinDubai from './Pages/Annual Maintenance Contract in Dubai';
import AMCindubai from './Pages/AMCindubai';
import AMCPackagesDubaiAnnualMaintenanceContract from './Pages/AMC Packages Dubai Annual Maintenance Contract';


import AMC1 from './Pages/AMC1';
import AMC2 from './Pages/AMC2';
import AMC3 from './Pages/AMC3';
import AMC4 from './Pages/AMC4';
import AMC5 from './Pages/AMC5';
import AMC6 from './Pages/AMC6';
import AMC7 from './Pages/AMC7';
import AMC8 from './Pages/AMC8';


import GardenMaintenanceServicesDubai from './Pages/Garden Maintenance Services Dubai';
import TopRatedLandscapingAndPlantMaintenanceServicesDubai from './Pages/Top-Rated Landscaping and Plant Maintenance Services Dubai';
import PremiumGardenMaintenanceServicesDubai from './Pages/Premium Garden Maintenance Services Dubai';
import BestLandscapingAndGardeningServicesCompanyInDubai from './Pages/Best Landscaping & Gardening Services Company in Dubai';
import GardenLandscapingDubaiExpertGardenCare from './Pages/Garden Landscaping Dubai - Expert Garden Care';
import BookProfessionalGardenMaintenanceServicesInDubai from './Pages/Book Professional Garden Maintenance Services In Dubai';
import GardenLandscapingDubaiTrustedGardenersInDubai from './Pages/Garden Landscaping Dubai Trusted Gardeners in Dubai';
import DubaiBestLandscapingAndGardenMaintenance from './Pages/Dubai Best Landscaping And Garden Maintenance';
import YourTopLandscapingCompanyInDubai from './Pages/Your Top Landscaping Company in Dubai';
import TopIndoorPlantsMaintenanceServiceDubai from './Pages/Top Indoor Plants Maintenance Service Dubai';
import LandscapeAndGardeningWorksAndMaintenanceInDubai from './Pages/Landscape & Gardening works & Maintenance in Dubai';
import AffordableGardeningServicesInDubai from './Pages/Affordable Gardening Services in Dubai';
import LandscapingAndGardeningServicesCompanyDubai from './Pages/Landscaping and Gardening services company – Dubai';
import UrbanLandscapesLuxuryLandscapingDesignDubai from './Pages/Urban Landscapes Luxury Landscaping Design Dubai';
import BestDubaiLandscapeDubaiTopLandscapingFirm from "./Pages/Best Dubai Landscape Dubai's top landscaping firm";
import OneOfTheBestLandscapingCompaniesInDubai from './Pages/One of the best landscaping companies in Dubai';
import PremiumLandscapingCompanyInDubai from './Pages/Premium Landscaping Company in Dubai';
import BestChoiceLandscapeGardeningLLCDubai from './Pages/Best Choice Landscape Gardening LLC Dubai';
import LandscapingInDubaiGreenLawnLandscapingAndGardening from './Pages/Landscaping in Dubai Greenlawn Landscaping & gardening';
import BestLandscapingAndLawnServiceInDubai from './Pages/Best Landscaping And Lawn Service In Dubai';
import ExpertGardenLandscapingInDubaiOutdoorOasis from './Pages/Expert Garden Landscaping in Dubai for Your Outdoor Oasis';
import BestGardeningCompaniesLandscapingWorkInDubai from './Pages/Best Gardening Companies Landscaping Work in Dubai';
import DubaiLandscapeHomeOfficeLandscapeIndoorOutdoor from './Pages/Dubai Landscaping Home office Landscape Indoor Outdoor1';
import BestLandscapingCompaniesInDubaiVillaGarden from './Pages/Best Landscaping Companies in Dubai-Villa Garden';
import LandscapingServicesInUaeLandscapingCompanyInDubai from './Pages/Landscaping Services in UAE Landscaping Company in Dubai';
import SwimmingPoolAndLandscapingCompanyInDubaiUae from './Pages/Swimming Pool and Landscaping Company in Dubai, UAE';



// Indoor Plant Maintenance Service (AMC) in Dubai
// Annual Maintenance Contract Dubai | AMC Services in UAE
// Garden Maintenance AMC Services at Discounted Prices
// Dubai Landscape Maintenance - Beautiful Gardens Made Easy
// Garden Maintenance AMC Services at Best Offers
// Looking For AMC for Your Lawn? | Garden Maintenance Services
// Garden Maintenance Services | Landscaping Services in Dubai 
// Annual Indoor Plant Maintenance #1 Plant care service Dubai





// Landscaping Companies in Dubai - Landscaping in Dubai
// Landscaping Company Dubai | Landscaping designer
// Best Landscaping Company in Dubai
// Landscaping Dubai | Landscaping Company Dubai 
// Desert Group | Best and Quality Landscaping Company in Dubai
// Premium Landscaping Company in Dubai 
// Best in Dubai Landscapes | Best UAE Landscaping
// Landscaping Services Dubai
// Landscaping Services | Garden Landscaping Company in Dubai
// Your Top Landscaping Company in Dubai | Planters
// Best landscape architecture in Dubai - Ducon Outdoor Living
// Landscaping and Gardening services company – Dubai
// Landscaping Services | Dubai | Abu Dhabi | UAE
// One of the best landscaping companies in Dubai
// Best Landscaping Company in Dubai | UAE
// Gardening Services in Dubai | Top Landscaping & Gardening


import Land1 from './Pages/Land1';
import Land2 from './Pages/Land2';
import Land3 from './Pages/Land3';
import Land4 from './Pages/Land4';
import Land5 from './Pages/Land5';
import Land6 from './Pages/Land6';
import Land7 from './Pages/Land7';
import Land8 from './Pages/Land8';
import Land9 from './Pages/Land9';
import Land10 from './Pages/Land10';
import Land11 from './Pages/Land11';
import Land12 from './Pages/Land12';
import Land13 from './Pages/Land13';
import Land14 from './Pages/Land14';
import Land15 from './Pages/Land15';
import Land16 from './Pages/Land16';










import LandscapingPage1 from './Pages/LandscapingPage (1)';
import LandscapingPage2 from './Pages/LandscapingPage (2)';
import LandscapingPage3 from './Pages/LandscapingPage (3)';
import LandscapingPage4 from './Pages/LandscapingPage (4)';
import LandscapingPage5 from './Pages/LandscapingPage (5)';
import LandscapingPage6 from './Pages/LandscapingPage (6)';
import LandscapingPage7 from './Pages/LandscapingPage (7)';
import LandscapingPage8 from './Pages/LandscapingPage (8)';
import LandscapingPage9 from './Pages/LandscapingPage (9)';
import LandscapingPage10 from './Pages/LandscapingPage (10)';
import LandscapingPage11 from './Pages/LandscapingPage (11)';
import LandscapingPage12 from './Pages/LandscapingPage (12)';
import LandscapingPage13 from './Pages/LandscapingPage (13)';
import LandscapingPage14 from './Pages/LandscapingPage (14)';
import LandscapingPage15 from './Pages/LandscapingPage (15)';
import LandscapingPage16 from './Pages/LandscapingPage (16)';
import LandscapingPage17 from './Pages/LandscapingPage (17)';
import LandscapingPage18 from './Pages/LandscapingPage (18)';
import LandscapingPage19 from './Pages/LandscapingPage (19)';
import LandscapingPage20 from './Pages/LandscapingPage (20)';
import LandscapingPage21 from './Pages/LandscapingPage (21)';
import LandscapingPage22 from './Pages/LandscapingPage (22)';
import LandscapingPage23 from './Pages/LandscapingPage (23)';
import LandscapingPage24 from './Pages/LandscapingPage (24)';
import LandscapingPage25 from './Pages/LandscapingPage (25)';
import LandscapingPage26 from './Pages/LandscapingPage (26)';






import Landscape1 from './Pages/Landscape (1)';
import Landscape2 from './Pages/Landscape (2)';
import Landscape3 from './Pages/Landscape (3)';
import Landscape4 from './Pages/Landscape (4)';
import Landscape5 from './Pages/Landscape (5)';
import Landscape6 from './Pages/Landscape (6)';
import Landscape7 from './Pages/Landscape (7)';
import Landscape8 from './Pages/Landscape (8)';
import Landscape9 from './Pages/Landscape (9)';
import Landscape10 from './Pages/Landscape (10)';
import Landscape11 from './Pages/Landscape (11)';
import Landscape12 from './Pages/Landscape (12)';
import Landscape13 from './Pages/Landscape (13)';
import Landscape14 from './Pages/Landscape (14)';
import Landscape15 from './Pages/Landscape (15)';
import Landscape16 from './Pages/Landscape (16)';
import Landscape17 from './Pages/Landscape (17)';
import Landscape18 from './Pages/Landscape (18)';
import Landscape19 from './Pages/Landscape (19)';
import Landscape20 from './Pages/Landscape (20)';
import Landscape21 from './Pages/Landscape (21)';
import Landscape22 from './Pages/Landscape (22)';
import Landscape23 from './Pages/Landscape (23)';














import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import GardenAccessories from './Pages/GardenAccessories';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import AMC from './Pages/AMC';
import Land from './Pages/land';
import Error from './Pages/Error';
// import submain from './Pages/submain';


import B1 from './Pages/benefits-of-annual-maintenance-contracts-for-your-garden-plant-in-dubai-with-garden-oasis-landscape-service';
import B2 from './Pages/get-high-quality-artificial-grass-and-transform-your-outdoor-space-at-our-store';
import B3 from './Pages/what-is-landscape-design';
import Sitemap from './Pages/Sitemap';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';
// import Error from './Pages/Error';



import whatapps1 from './assets/images/png.png';



// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";





function App() {
  
  return (
    <div className="App" >                    
     {/* <a href="https://wa.me/971042766276" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "10px",width:"70px",height : "70px"}}/></a> */}
     <a href="tel:+971509480695" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "15px",width:"50px",height : "50px"}}/></a>
     <a href="https://wa.me/971509480695?text=Hi *Garden Oasis Landscape *! I need more info about Garden Oasis Landscape  https://gardenoasislandscape.com" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "72%" , right : "10px",width:"60px",height : "60px"}}/></a>
     
      <Router>

        <Header />
        
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/client' component={Client} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />

          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/sitemap' component={Sitemap} />
          <Route exact path='/search/product-item' component={SearchData} />
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/categories-main/Annual Maintenance Contract' component={AMC} />
          <Route exact path='/categories-main/Landscaping' component={Land} />
          {/* <Route exact path='/categories-main/Garden Accessories' component={GardenAccessories} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />

          <Route exact path='/villa-maintenance-contract-dubai-villa-amc-dubai' component={VillaMaintenanceContractDubaiVillaAMCDubai} />
          <Route exact path='/best-annual-maintenance-contract-packages' component={BestAnnualMaintenanceContractPackages} />
          <Route exact path='/annual-maintenance-contract-in-dubai' component={AnnualMaintenanceContractinDubai} />
          <Route exact path='/annual-maintenance-contracts-dubai' component={AMCindubai} />
          <Route exact path='/amc-packages-dubai-annual-maintenance-contract' component={AMCPackagesDubaiAnnualMaintenanceContract} />
          
          
          <Route exact path='/Indoor Plant Maintenance Service (AMC) in Dubai' component={AMC1} />
          <Route exact path='/Annual Maintenance Contract Dubai AMC Services in UAE' component={AMC2} />
          <Route exact path='/Garden Maintenance AMC Services at Discounted Prices' component={AMC3} />
          <Route exact path='/Dubai Landscape Maintenance - Beautiful Gardens Made Easy' component={AMC4} />
          <Route exact path='/Garden Maintenance AMC Services at Best Offers' component={AMC5} />
          <Route exact path='/Looking For AMC for Your Lawn? Garden Maintenance Services' component={AMC6} />
          <Route exact path='/Garden Maintenance Services Landscaping Services in Dubai' component={AMC7} />
          <Route exact path='/Annual Indoor Plant Maintenance Plant care service Dubai' component={AMC8} />
          
          <Route exact path='/Landscaping Companies in Dubai - Landscaping in Dubai' component={Land1} />
          <Route exact path='/Landscaping Company Dubai Landscaping designer' component={Land2} />
          <Route exact path='/Best Landscaping Company in Dubai' component={Land3} />
          <Route exact path='/Landscaping Dubai | Landscaping Company Dubai' component={Land4} />
          <Route exact path='/Desert Group | Best and Quality Landscaping Company in Dubai' component={Land5} />
          <Route exact path='/Premium Landscaping Company in Dubai' component={Land6} />
          <Route exact path='/Best in Dubai Landscapes | Best UAE Landscaping' component={Land7} />
          <Route exact path='/Landscaping Services Dubai' component={Land8} />
          <Route exact path='/Landscaping Services | Garden Landscaping Company in Dubai' component={Land9} />
          <Route exact path='/Your Top Landscaping Company in Dubai | Planters' component={Land10} />
          <Route exact path='/Best landscape architecture in Dubai - Outdoor Living' component={Land11} />
          <Route exact path='/Landscaping and Gardening services company – Dubai' component={Land12} />
          <Route exact path='/Landscaping Services | Dubai | UAE' component={Land13} />
          <Route exact path='/One of the best landscaping companies in Dubai' component={Land14} />
          <Route exact path='/Best Landscaping Company in Dubai | UAE' component={Land15} />
          <Route exact path='/Gardening Services in Dubai | Top Landscaping & Gardening' component={Land16} />
          
          
          <Route exact path='/benefits-of-annual-maintenance-contracts-for-your-garden-plant-in-dubai-with-garden-oasis-landscape-service' component={B1} />
          <Route exact path='/get-high-quality-artificial-grass-and-transform-your-outdoor-space-at-our-store' component={B2} />
          <Route exact path='/what-is-landscape-design' component={B3} />
      
      
          <Route exact path='/garden-maintenance-services-dubai' component={GardenMaintenanceServicesDubai } />
          <Route exact path='/top-rated-landscaping-and-plant-maintenance-services-dubai' component={TopRatedLandscapingAndPlantMaintenanceServicesDubai } />
          <Route exact path='/premium-garden-maintenance-services-dubai' component={PremiumGardenMaintenanceServicesDubai } />
          <Route exact path='/best-landscaping-and-gardening-services-company-in-dubai' component={BestLandscapingAndGardeningServicesCompanyInDubai } />
          <Route exact path='/garden-landscaping-dubai-expert-garden-care' component={GardenLandscapingDubaiExpertGardenCare } />
          <Route exact path='/book-professional-garden-maintenance-services-in-dubai' component={BookProfessionalGardenMaintenanceServicesInDubai } />
          <Route exact path='/garden-landscaping-dubai-trusted-#1-gardeners-in-dubai' component={GardenLandscapingDubaiTrustedGardenersInDubai } />
          <Route exact path='/dubai-best-landscaping-and-garden-maintenance' component={DubaiBestLandscapingAndGardenMaintenance } />
          <Route exact path='/your-top-landscaping-company-in-dubai' component={YourTopLandscapingCompanyInDubai } />
          <Route exact path='/top-indoor-plants-maintenance-service-dubai' component={TopIndoorPlantsMaintenanceServiceDubai } />
          <Route exact path='/landscape-and-gardening-works-and-maintenance-in-dubai' component={LandscapeAndGardeningWorksAndMaintenanceInDubai } />
          <Route exact path='/affordable-gardening-services-in-dubai' component={AffordableGardeningServicesInDubai } />
          <Route exact path='/landscaping-and-gardening-services-company-dubai' component={LandscapingAndGardeningServicesCompanyDubai } />
          <Route exact path='/urban-landscapes-luxury-landscaping-design-dubai' component={UrbanLandscapesLuxuryLandscapingDesignDubai } />
          <Route exact path='/best-dubai-landscape-dubai-top-landscaping-firm' component={BestDubaiLandscapeDubaiTopLandscapingFirm } />
          <Route exact path='/one-of-the-best-landscaping-companies-in-dubai' component={OneOfTheBestLandscapingCompaniesInDubai } />
          <Route exact path='/premium-landscaping-company-in-dubai' component={PremiumLandscapingCompanyInDubai } />
          <Route exact path='/best-choice-landscape-gardening-llc-dubai' component={BestChoiceLandscapeGardeningLLCDubai } />
          <Route exact path='/landscaping-in-dubai-greenlawn-landscaping-and-gardening' component={LandscapingInDubaiGreenLawnLandscapingAndGardening } />
          <Route exact path='/best-landscaping-and-lawn-service-in-dubai' component={BestLandscapingAndLawnServiceInDubai } />
          <Route exact path='/expert-garden-landscaping-in-dubai-for-your-outdoor-oasis' component={ExpertGardenLandscapingInDubaiOutdoorOasis } />
          <Route exact path='/best-gardening-companies-landscaping-work-in-dubai' component={BestGardeningCompaniesLandscapingWorkInDubai } />
          <Route exact path='/dubai-landscaping-home-office-landscape-indoor-outdoor-#1' component={DubaiLandscapeHomeOfficeLandscapeIndoorOutdoor } />
          <Route exact path='/best-landscaping-companies-in-dubai-villa-garden' component={BestLandscapingCompaniesInDubaiVillaGarden } />
          <Route exact path='/landscaping-services-in-uae-landscaping-company-in-dubai' component={LandscapingServicesInUaeLandscapingCompanyInDubai } />
          <Route exact path='/#1-swimming-pool-and-landscaping-company-in-dubai-uae' component={SwimmingPoolAndLandscapingCompanyInDubaiUae} />
       


      
   <Route exact path='/best-landscaping-company-arabian-ranches' component={LandscapingPage1} />
   <Route exact path='/best-landscaping-company-dubai-marina' component={LandscapingPage2} />
   <Route exact path='/best-landscaping-company-jumeirah-beach-residence' component={LandscapingPage3} />
   <Route exact path='/best-landscaping-company-al-barsha' component={LandscapingPage4} />
   <Route exact path='/best-landscaping-company-downtown-dubai' component={LandscapingPage5} />
   <Route exact path='/best-landscaping-company-business-bay' component={LandscapingPage6} />
   <Route exact path='/best-landscaping-company-al-quoz' component={LandscapingPage7} />
   <Route exact path='/top-garden-design-company-dubai' component={LandscapingPage8} />
   <Route exact path='/best-tree-supplier-dubai' component={LandscapingPage9} />
   <Route exact path='/best-tree-exporter-dubai' component={LandscapingPage10} />
   <Route exact path='/leading-irrigation-system-provider-dubai' component={LandscapingPage11} />
   <Route exact path='/premium-plant-nursery-dubai' component={LandscapingPage12} />
   <Route exact path='/best-outdoor-lighting-solutions-dubai' component={LandscapingPage13} />
   <Route exact path='/best-landscape-maintenance-company-dubai' component={LandscapingPage14} />
   <Route exact path='/top-hardscape-company-dubai' component={LandscapingPage15} />
   <Route exact path='/best-landscaping-service-arabian-ranches-1-2' component={LandscapingPage16} />
   <Route exact path='/best-landscaping-service-dubai-hills-estate' component={LandscapingPage17} />
   <Route exact path='/best-landscaping-service-emirates-hills' component={LandscapingPage18} />
   <Route exact path='/best-landscaping-service-jumeirah' component={LandscapingPage19} />
   <Route exact path='/best-landscaping-service-jumeirah-golf-estate' component={LandscapingPage20} />
   <Route exact path='/best-landscaping-service-dubai-investment-park' component={LandscapingPage21} />
   <Route exact path='/best-landscaping-service-dubai-international-centre' component={LandscapingPage22} />
   <Route exact path='/best-landscaping-service-dubai' component={LandscapingPage23} />
   <Route exact path='/best-landscaping-service-uae' component={LandscapingPage24} />
   <Route exact path='/no1-landscaping-company-dubai' component={LandscapingPage25} />
   <Route exact path='/top-landscaping-company-dubai' component={LandscapingPage26} />







   <Route exact path='/landscape-architects-dubai' component={Landscape1} />
<Route exact path='/landscape-design-company' component={Landscape2} />
<Route exact path='/commercial-landscaping-company-in-dubai' component={Landscape3} />
<Route exact path='/residential-landscaping-company-in-dubai' component={Landscape4} />
<Route exact path='/hardscaping-company-in-dubai' component={Landscape5} />
<Route exact path='/garden-installation-company-in-dubai' component={Landscape6} />
<Route exact path='/horticulture-services-dubai' component={Landscape7} />
<Route exact path='/outdoor-landscaping-service' component={Landscape8} />
<Route exact path='/outdoor-landscaping-service-in-dubai' component={Landscape9} />
<Route exact path='/outdoor-plant-service-in-dubai' component={Landscape10} />
<Route exact path='/outdoor-plant-company-in-dubai' component={Landscape11} />
<Route exact path='/plant-supply-dubai' component={Landscape12} />
<Route exact path='/plant-wholesale-dubai' component={Landscape13} />
<Route exact path='/plant-wholesale-arjan' component={Landscape14} />
<Route exact path='/plant-wholesale-shop-dubai' component={Landscape15} />
<Route exact path='/best-lawn-care-services' component={Landscape16} />
<Route exact path='/grass-installation-dubai' component={Landscape17} />
<Route exact path='/landscaping-service-arjan' component={Landscape18} />
<Route exact path='/landscaping-service-uae' component={Landscape19} />
<Route exact path='/landscaping-company-arjan' component={Landscape20} />
<Route exact path='/garden-shop-arjan-dubai' component={Landscape21} />
<Route exact path='/plant-shop-arjan-dubai' component={Landscape22} />
<Route exact path='/indoor-plant-shop-arjan-dubai' component={Landscape23} />











          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/' component={} /> */}
          {/* <Route exact path='/track' component={tracking} /> */}
          
          

          {/* 
          Garden Maintenance Services Dubai
Top-Rated Landscaping and Plant Maintenance Services Dubai
Premium Garden Maintenance Services Dubai
Best Landscaping & Gardening Services Company in Dubai
Garden Landscaping Dubai - Expert Garden Care
Book Professional Garden Maintenance Services In Dubai
Garden Landscaping Dubai | Trusted #1 Gardeners in Dubai
Dubai Best Landscaping And Garden Maintenance
Your Top Landscaping Company in Dubai 
Top Indoor Plants Maintenance Service Dubai 
Landscape & Gardening works & Maintenance in Dubai
Affordable Gardening Services in Dubai
Landscaping and Gardening services company – Dubai
Urban Landscapes | Luxury Landscaping Design Dubai
Best Dubai Landscape | Dubai's top landscaping firm
One of the best landscaping companies in Dubai
Premium Landscaping Company in Dubai
Best Choice Landscape Gardening LLC Dubai
Landscaping in Dubai | Greenlawn Landscaping & gardening
Best Landscaping And Lawn Service In Dubai
Expert Garden Landscaping in Dubai for Your Outdoor Oasis
Best Gardening Companies Landscaping Work in Dubai
Dubai Landscaping | Home office Landscape Indoor Outdoor #1
Best Landscaping Companies in Dubai-Villa Garden
Landscaping Services in UAE: Landscaping Company in Dubai
#1 Swimming Pool and Landscaping Company in Dubai, UAE */}






          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
