import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

import client1 from '../assets/images/client (1).jpg';
import client2 from '../assets/images/client (2).jpg';
import client3 from '../assets/images/client (3).jpg';
import client4 from '../assets/images/client (4).jpg';
import client5 from '../assets/images/client (5).jpg';
import client6 from '../assets/images/client (6).jpg';
import client7 from '../assets/images/client (7).jpg';

// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">Client</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a >Our Client</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



        

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client1} alt="" />
            </div>
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client2} alt="" />
            </div>
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client3} alt="" />
            </div>
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client4} alt="" />
            </div>
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client5} alt="" />
            </div>
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client6} alt="" />
            </div>
            <div className="col-lg-2 col-sm-12" style={{margin  :"auto"}}>
                <img src={client7} alt="" />
            </div>
            </div>
            </div>


          


            {/* </div> */}
        
          </div>
        )
    }
}

export default About;